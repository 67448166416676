<template>
  <v-container>
    <v-skeleton-loader
      class="mx-auto"
      type="article@3"
      v-if="!show"
    ></v-skeleton-loader>
    <div v-else>
      <h1 class="center">New Onboarding!</h1>
      <br />
      <h3>Name: {{ person.FirstName }} {{ person.LastName }}</h3>
      <br />
      <v-row>
        <v-col>
          <h4>Private info</h4>
          <p>SSN: {{ person.PrivateInfo.SSN }}</p>
          <p>e-mail: {{ person.PrivateInfo.Email }}</p>
          <p>Phone number: {{ person.PrivateInfo.Phone }}</p>
          <p>Street address: {{ person.PrivateInfo.Address }}</p>
          <p>City: {{ person.PrivateInfo.City }}</p>
          <p>Zip: {{ person.PrivateInfo.Zip }}</p>
        </v-col>
        <v-col>
          <h4>Job info</h4>
          <p>Start date: {{ person.StartDate }}</p>
          <p>Job Title: {{ person.JobInfo.JobTitle }}</p>
          <p>e-mail: {{ person.JobInfo.Email }}</p>
          <p>Team / Manager: {{ person.JobInfo.Team }}</p>
          <p>Phone: {{ person.JobInfo.Phone }}</p>
          <p>Office: {{ person.JobInfo.Office }}</p>
          <p>Department: {{ person.JobInfo.Department }}</p>
        </v-col>
      </v-row>
      <v-row v-if="azureInfo">
        <v-col>
          <h2>Azure info</h2>
          <p>Display name: {{ azureInfo.displayName }}</p>
          <p>Department: {{ azureInfo.department }}</p>
          <p>mobile phone: {{ azureInfo.mobilePhone }}</p>
          <p>Manager: {{ azureInfoManager.displayName }}</p>
          <h4>Office address</h4>
          <p>City: {{ azureInfo.city }}</p>
          <p>Address: {{ azureInfo.streetAddress }}</p>
          <p>Postal code: {{ azureInfo.postalCode }}</p>
          <p>State: {{ azureInfo.state }}</p>
          <p>Country: {{ azureInfo.country }}</p>
        </v-col>
      </v-row>

      <v-btn
        v-if="!person.JobInfo.Email"
        color="primary"
        elevation="7"
        large
        right
        fixed
        bottom
        @click="createAzure"
        >Create Azure account</v-btn
      >

      <v-btn
        v-if="person.JobInfo.Email"
        color="primary"
        elevation="7"
        large
        right
        fixed
        bottom
        @click="update"
        >Update person info</v-btn
      >
    </div>
    <!-- give feedback to user -->
    <v-snackbar v-model="snackbar" :timeout="timeout" :color="color">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  import {
    createPersonToAzure,
    getAllgroupsWithExpand,
    addManagerToUser,
    getAzureUser,
    getAzureUsersManager,
    updateUser,
  } from "../services/msGraphActions.js";
  import {
    updatePerson,
    addCompletedWithText,
  } from "../services/firestoreRoutes";

  export default {
    props: ["personId"],
    data() {
      return {
        person: {},
        show: false,
        AllteamManagersGroupLicenseIDs: [],
        teamManagersGroupLicenseIDs: [],
        officeVibeGroups: [],
        teamManagers: [],
        teams: [],
        snackbar: false,
        timeout: 2000,
        text: "",
        color: "",
        azureInfo: "",
        azureInfoManager: [],
        azureUserId: "",
      };
    },
    watch: {
      "$store.state.onboardingPeople.people": function () {
        this.person = this.$store.state.onboardingPeople.people.find(
          (x) => x.id === this.personId
        );
        this.show = true;
      },
      deep: true,
    },

    async mounted() {
      //get all groups with expand owners
      const groups = await getAllgroupsWithExpand();
      await this.person;
      if (this.show) {
        if (this.person.JobInfo.Email) {
          this.azureInfo = await getAzureUser(this.person.JobInfo.Email);
          this.azureInfoManager = await getAzureUsersManager(
            this.person.JobInfo.Email
          );
        }
      }

      //Get all vibe groups from group list
      for (let i = 0; i < groups.value.length; i++) {
        const group = groups.value[i];
        if (group.displayName.toLowerCase().includes("vibe")) {
          this.officeVibeGroups.push(group.displayName + " :" + group.id);
        }
      }

      for (let i = 0; i < groups.value.length; i++) {
        const group = groups.value[i];
        // for (let vibeIndex = 0;vibeIndex < this.officeVibeGroups.length;vibeIndex++) {
        //   const displayName = this.officeVibeGroups[vibeIndex];
        //   if (group.displayName == displayName && group.displayName.includes(displayName)) {
        //     this.teamManagersGroupLicenseIDs.push(group.displayName + " - " + group.displayName + ":" + group.id);
        //   }
        // }
        if (group.owners.length >= 1) {
          for (let y = 0; y < group.owners.length; y++) {
            const owners = group.owners[y];

            if (owners.jobTitle == "Team Manager") {
              if (!this.teamManagers.includes(owners.displayName)) {
                this.teamManagers.push(
                  owners.department + " -- " + owners.displayName
                );
                this.teams.push(owners);

                for (let o = 0; o < this.officeVibeGroups.length; o++) {
                  const office = this.officeVibeGroups[o];
                  if (office.includes(owners.department)) {
                    if (!this.AllteamManagersGroupLicenseIDs.includes(office)) {
                      this.AllteamManagersGroupLicenseIDs.push(office);
                    }
                  }
                }

                if (
                  group.displayName
                    .toLowerCase()
                    .includes(owners.department.toLowerCase())
                ) {
                  this.AllteamManagersGroupLicenseIDs.push(
                    owners.department +
                      " -- " +
                      owners.displayName +
                      ":" +
                      group.id
                  );
                }
              }
            }
          }
        }
      }
      if (this.show === true) {
        const team = this.person.JobInfo.Team;

        this.teamManagersGroupLicenseIDs = [];
        const CoreTeamName = team.slice(0, team.indexOf(" "));

        for (let i = 0; i < this.AllteamManagersGroupLicenseIDs.length; i++) {
          if (
            this.AllteamManagersGroupLicenseIDs[i].includes(team) ||
            this.AllteamManagersGroupLicenseIDs[i].includes(CoreTeamName)
          ) {
            this.teamManagersGroupLicenseIDs.push(
              this.AllteamManagersGroupLicenseIDs[i]
            );
          }
        }
      }
    },
    computed: {},
    methods: {
      async createAzure() {
        const azureUser = {
          accountEnabled: true,
          passwordProfile: {
            password: "d9dda358-76dc",
            forceChangePasswordNextSignIn: true,
          },
          mailNickname: this.person.FirstName + this.person.LastName,
          businessPhones: [],
          displayName: this.person.FirstName + " " + this.person.LastName,
          givenName: this.person.FirstName,
          surname: this.person.LastName,
          jobTitle: this.person.JobInfo.JobTitle,
          mail:
            this.person.FirstName + "." + this.person.LastName + "@geshdo.com",
          // mobilePhone: person.PrivateInfo.Phone,
          officeLocation: "HQ",
          preferredLanguage: "sv-SE",
          userPrincipalName:
            this.person.FirstName + "." + this.person.LastName + "@geshdo.com",
          usageLocation: "SE",
          department: this.person.JobInfo.Department,
          employeeHireDate: this.person.StartDate,
          streetAddress: "",
          city: this.person.JobInfo.office,
          state: "",
          postalCode: "",
          country: "",
        };

        if (this.person.JobInfo.office === "Malmö") {
          azureUser.streetAddress = "Pildammsvägen 6B";
          azureUser.state = "Skåne";
          azureUser.postalCode = "211 46";
          azureUser.country = "Sweden";
        } else if (this.person.JobInfo.office === "Göteborg") {
          azureUser.country = "Sweden";
          azureUser.state = "Göteborg";
          azureUser.streetAddress = "Lilla bommen 1";
          azureUser.postalCode = "411 04";
        }

        const createPersonToAzureResponse = await createPersonToAzure(
          azureUser,
          this.teamManagersGroupLicenseIDs
        );

        if (createPersonToAzureResponse.id) {
          this.person.JobInfo.Email = createPersonToAzureResponse.mail;
          const updatePersonFormat = { ...this.person };
          this.$delete(updatePersonFormat, "tasks");
          this.$delete(updatePersonFormat, "id");
          const body = { id: this.personId, person: updatePersonFormat };
          await updatePerson(body);
          const doneTaskBody = {
            id: this.personId,
            text: "Create an GESHDO-email account",
          };
          addCompletedWithText(doneTaskBody);

          const managerName = this.person.JobInfo.Team.substring(
            this.person.JobInfo.Team.indexOf("--") + 3
          );
          const personManager = this.teams.find(
            (x) => x.displayName === managerName
          );

          addManagerToUser(createPersonToAzureResponse.id, personManager.id);

          this.snackbar = true;
          this.text = "You have created a account in Azure";
          this.color = "success";
        } else if (createPersonToAzureResponse === "exists") {
          this.snackbar = true;
          this.text = "The person already exists";
          this.color = "red accent-2";
        } else if (
          createPersonToAzureResponse ===
          "Could not crate user, something went wrong with creating groups"
        ) {
          this.snackbar = true;
          this.text =
            "Could not crate user, something went wrong with creating groups";
          this.color = "red accent-2";
        } else if (createPersonToAzureResponse === "Could not create user") {
          this.snackbar = true;
          this.text = "Could not create user";
          this.color = "red accent-2";
        } else {
          this.responseMessage = "error";
          this.snackbar = true;
          this.text = "Something went wrong";
          this.color = "red accent-2";
        }
      },
      async update() {
        const updatedAzureUser = {
          department: this.person.JobInfo.Department,
          employeeHireDate: this.person.StartDate,
          streetAddress: "",
          city: this.person.JobInfo.office,
          state: "",
          postalCode: "",
          country: "",
        };
        if (this.person.JobInfo.Office === "Malmö") {
          updatedAzureUser.streetAddress = "Pildammsvägen 6B";
          updatedAzureUser.state = "Skåne";
          updatedAzureUser.postalCode = "211 46";
          updatedAzureUser.country = "Sweden";
        } else if (this.person.JobInfo.Office === "Göteborg") {
          updatedAzureUser.country = "Sweden";
          updatedAzureUser.state = "Göteborg";
          updatedAzureUser.streetAddress = "Lilla bommen 1";
          updatedAzureUser.postalCode = "411 04";
        }

        const managerName = this.person.JobInfo.Team.substring(
          this.person.JobInfo.Team.indexOf("--") + 3
        );

        if (managerName != this.azureInfoManager.displayName) {
          const personManager = this.teams.find(
            (x) => x.displayName === managerName
          );

          await addManagerToUser(this.azureInfo.id, personManager.id);
        }

        const res = await updateUser(
          this.person.JobInfo.Email,
          updatedAzureUser
        );

        if (res) {
          this.snackbar = true;
          this.text = "You have updated a account in Azure";
          this.color = "success";
        } else {
          this.snackbar = true;
          this.text = "Something went wrong";
          this.color = "red accent-2";
        }
      },
    },
  };
</script>

<style scoped>
  .center {
    text-align: center;
    padding-top: 10px;
  }
  .icon {
    font-size: 80px;
  }
</style>
